import React from "react";
import styled from "styled-components";
import useStore from "../../../../context/useStore";

const StatusIcon = ({ status, onClick, showName }) => {
  const [state] = useStore();
  const { LEAD_STATUS } = state.constants.items;
  const statusRef = LEAD_STATUS.find((l) => l.id == status);

  return (
    <div
      className={`d-flex align-items-center mt-md-2 justify-content-${
        showName ? "start" : "center"
      } w-100`}
      onClick={onClick}
    >
      <StyledStatusIcon
        className="shadow border"
        statusRef={statusRef}
        title={statusRef.name}
      >
        <i className={`animated bounceIn text-white fa fa-${statusRef.icon}`} />
      </StyledStatusIcon>
      {showName && (
        <span
          className="pl-2 cursor-pointer"
          style={{ color: `var(--${statusRef.color})` }}
        >
          {statusRef.name}
        </span>
      )}
    </div>
  );
};

const StyledStatusIcon = styled.div`
  min-width: 40px;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ statusRef }) => `var(--${statusRef.color})`};
  border-radius: 50%;
  font-size: 18px;
  cursor: pointer;
  transition: 400ms;
  border: 1px solid white;
`;

export default StatusIcon;
