import { DateTime } from "luxon";
import React, { useEffect } from "react";
import styled from "styled-components";
import Loader from "../../../../../../../../../common/Loader";
import DropZone from "./DropZone";
import OlderDocs from "./OlderDocs";
import useBPDocumentItem from "./useBPDocumentItem";

const docTypes = {
  COMPANY: {
    modify: "bo_modifycompanydocs",
    view: "bo_viewcompanydocs",
  },
  POLICY: {
    modify: "bo_modifycontractdocs",
  },
};

const BPDocumentItem = ({ doc, docType = "", nbDocs = 0, docMeta = {} }) => {
  const {
    document,
    isDownloadingDocId,
    isRemovingDocId,
    isUploadingDocId,
    showOlder,
    docInfos,
    otherListRef,

    actions: { onDownload, onRemove, onUpload, toggleShowOlder },
  } = useBPDocumentItem(doc, docMeta);
  const boundRole = docTypes[docType] || null;

  useEffect(() => {
    if (showOlder) otherListRef.current.scrollTop = 180;
  }, [showOlder]);

  return (
    <StyledDocumentItem className="shadow-sm border" ref={otherListRef}>
      <div>
        {docInfos.id && (
          <div className="doc__count bg-primary text-white font-weight-bold">
            {nbDocs}
          </div>
        )}
        <div className="doc__name mb-2 font-weight-bold d-center">
          {docInfos.fileType.name}
        </div>
        <p className="doc__date my-2">
          {docInfos.uploadedAt ? (
            <>
              Envoyé le{" "}
              {DateTime.fromISO(docInfos.uploadedAt).toFormat("dd/MM/yyyy")}
            </>
          ) : (
            <>Aucun fichier</>
          )}
        </p>
        {docInfos.fileName && (
          <div className="d-center flex-column mb-2">
            <button
              className="btn py-2 btn-primary "
              onClick={() => onDownload(docInfos)}
            >
              {isDownloadingDocId == docInfos.id ? <Loader /> : "Télécharger"}
            </button>
            <button
              data-priv={boundRole?.modify || ""}
              className="btn py-2 btn-link text-danger "
              onClick={() => onRemove(docInfos.id)}
            >
              {isRemovingDocId == docInfos.id ? <Loader /> : "Supprimer"}
            </button>
            {document.length > 1 && (
              <button
                className="btn btn-link py-2  text-dark font-weight-normal"
                onClick={toggleShowOlder}
              >
                {document.length - 1} autre(s)
                {/* <i className={`fa fa-chevron-${showOlder ? "up" : "down"}`} /> */}
              </button>
            )}
          </div>
        )}

        {showOlder && (
          <OlderDocs
            document={document}
            docMeta={docMeta}
            onDownload={onDownload}
            onRemove={onRemove}
            isDownloadingDocId={isDownloadingDocId}
            isRemovingDocId={isRemovingDocId}
            removeDataPriv={boundRole?.modify || ""}
          />
        )}
      </div>

      <DropZone
        dataPriv={boundRole?.modify || ""}
        onDropFiles={onUpload}
        isUploading={isUploadingDocId}
      />
    </StyledDocumentItem>
  );
};

const StyledDocumentItem = styled.div`
  width: 100%;
  height: 100%;
  padding: 10px;
  border-radius: 8px;
  background: white;
  overflow-y: auto;
  scroll-behavior: smooth;
  position: relative;
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  & .doc__count {
    position: absolute;
    left: 0;
    top: 0;
    padding: 10px;
    border-bottom-right-radius: 20px;
    width: 30px;
    text-align: center;
  }

  & .doc__name {
    height: 60px;
    overflow: hidden;
    font-size: 14px;
    text-align: center;
  }

  & .doc__date {
    font-weight: 200;
    text-align: center;
    font-size: 12px;
  }
`;

export default BPDocumentItem;
