import React from "react";
import {
  StyledFinalizedIcon,
  StyledLeadProduct,
  StyledProductStatus,
} from "../../../LeadListPage/LeadList/LeadItem/LeadProduct/LeadProduct";
import useSimplifiedFormItem from "../useSimplifiedFormItem";
import ProductIcon from "../../../LeadListPage/LeadList/LeadItem/LeadProduct/ProductIcon";

const ItemProduct = () => {
  const { simplifiedFormItem } = useSimplifiedFormItem();

  return (
    <StyledLeadProduct
      className="d-center"
      style={{ textTransform: "uppercase" }}
    >
      <div className="d-center flex-column w-100" style={{ gap: 3 }}>
        <ProductIcon product={simplifiedFormItem.parameters.product} />
        <div>{simplifiedFormItem.parameters.product}</div>
      </div>
      <StyledProductStatus>
        {simplifiedFormItem.est_finalise && (
          <StyledFinalizedIcon
            onClick={() => alert("Demande finalisée")}
            className="shadow cursor-pointer"
            color="success"
            title="Demande finalisée"
          >
            <i className="fa fa-check" />
          </StyledFinalizedIcon>
        )}
      </StyledProductStatus>
    </StyledLeadProduct>
  );
};

export default ItemProduct;
