import React from "react";
import useLeads from "../../useLeads";

const LeadCount = () => {
  const { isSearching, leadCount } = useLeads();

  return (
    <div className="text-center mt-2">
      {isSearching ? (
        "Recherche en cours..."
      ) : (
        <>{leadCount} demandes trouvées</>
      )}
    </div>
  );
};

export default LeadCount;
