import { useAtom } from "jotai";
import {
  activeCommentIdAtom,
  commentsAtom,
  commentUsersAtom,
} from "./atoms/commentListAtoms";

const useCommentList = (
  onUpdateComments = () => {},
  onUpdateComment = () => {},
  onAddComment = () => {},
  onDeleteComment = () => {}
) => {
  const [comments, setComments] = useAtom(commentsAtom);
  const [activeCommentId, setActiveCommentId] = useAtom(activeCommentIdAtom);
  const [commentUsers, setCommentUsers] = useAtom(commentUsersAtom);

  const setupComments = (initComments, users) => {
    setComments(initComments);
    setCommentUsers(users);
    if (initComments.length > 0) {
      setActiveCommentId(initComments[initComments.length - 1].id);
    }
  };

  const updateComment = (comment) => {
    const newComments = [
      ...comments.map((t) => (t.id == comment.id ? comment : t)),
    ];
    setComments(newComments);
    onUpdateComment(comment);
  };

  const addComment = (comment) => {
    const newComments = [...comments, comment];
    setComments(newComments);
    onAddComment(comment);
  };

  const deleteComment = (commentId) => {
    if (
      window.confirm("Confirmez-vous la suppression de ce commentaire ?") ==
      false
    )
      return;
    const newComments = [
      ...comments.filter((comment) => comment.id != commentId),
    ];
    setComments(newComments);

    onDeleteComment(commentId);
  };

  //ajouter updateComment pour modifier le texte d'une comment

  return {
    comments,
    setComments,
    activeCommentId,
    setActiveCommentId,
    commentUsers,
    setCommentUsers,
    actions: { addComment, deleteComment, updateComment },
    setupComments,
  };
};

export default useCommentList;
