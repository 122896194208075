import React, { useContext, useState } from "react";
import styled from "styled-components";
import useRole from "../../../../../../hooks/useRole/useRole";
import Avatar from "../../../../../common/Avatar";
import Control from "../../../../../common/Controls/Control";
import { LeadPageContext } from "../../../useLeads";
import { LeadContext } from "../../LeadContext";
import useLeadItem from "../useLeadItem";
import "./assignTo.scss";
import { DateTime } from "luxon";

const MANAGER_TYPES = [
  {
    id: "PERCEVAL",
    name: "Perceval",
  },
  { id: "PARTNER", name: "Partenaires" },
];

const AssignTo = () => {
  const { productCategory } = useContext(LeadPageContext);
  const { managers, myRole } = useContext(LeadContext);
  const { lead, actions } = useLeadItem();
  const initialSelectedRolesByProductCategory = {
    PRO: ["PERCEVAL", "PARTNER"],
    DOMI: ["PERCEVAL"],
  };
  const [selectedUserRoles, setSelectedUserRoles] = useState(
    initialSelectedRolesByProductCategory[productCategory]
  );

  const handleChangeManager = (e) => {
    actions.onUpdateAssignTo(e.target.value);
  };

  const handleChangeSelectedUserRole = (e) => {
    const { value } = e.target;
    if (value.length == 0) return;

    setSelectedUserRoles(value);
  };

  const filteredManagersBySelectedRole = managers
    .sort((a, b) => (a.id == lead.assignedTo ? -1 : 1))

    .filter((manager) => {
      if (!manager.roles.length > 0) return false;
      if (manager.id == lead.assignedTo) return true;
      if (
        manager.contractEndAt &&
        DateTime.now() > DateTime.fromISO(manager.contractEndAt)
      )
        return false;
      switch (manager.roles[0].roleKey) {
        case "PARTNER":
          return selectedUserRoles.includes("PARTNER") ? true : false;
        default:
          return selectedUserRoles.includes("PERCEVAL") ? true : false;
      }
    });

  /*   return managers groups for the selectGroup input with this structure :
   const groups = [
    {
      label: "Utilisateur", // is PERCEVAL
      options: [
        { id: 1, name: "Option 1-1" },
        { id: 2, name: "Option 1-2" },
      ],
    },
    {
      label: "Partenaire", // is PARTNER
      options: [
        { id: 3, name: "Option 2-1" },
        { id: 4, name: "Option 2-2" },
      ],
    },
  ];
  */
  console.log("manager", managers);

  const managersGroupsByRole = () => {
    const groups = [
      {
        label: "Utilisateurs", // is PERCEVAL
        options: filteredManagersBySelectedRole
          .filter((manager) =>
            manager.roles.some((role) => role.roleKey !== "PARTNER")
          )
          .map((manager) => ({
            id: manager.id,
            name: manager.fullName,
          })),
      },
      {
        label: "Partenaires", // is PARTNER
        options: filteredManagersBySelectedRole
          .filter((manager) =>
            manager.roles.some((role) => role.roleKey === "PARTNER")
          )
          .map((manager) => ({
            id: manager.id,
            name: manager.fullName,
          })),
      },
    ];

    return groups;
  };

  console.log("managersGroupsByRole", managersGroupsByRole());

  {
    /* {myRole !== "PARTNER" && productCategory == "PRO" && (
    <Control
      label=""
      type="checkBtnList"
      datas={MANAGER_TYPES}
      name="selectedUserRoles"
      dataIndex="id"
      dataLabel="name"
      change={handleChangeSelectedUserRole}
      value={selectedUserRoles}
      btnInline
      noPaddingOnFormGroup
      noMarginInsideLabel
      noBg
      labelClassname="pt-4"
      itemClassName="border-0 btn-default text-dark shadow-none"
      iconStyle={{
        top: 4,
        right: -10,
      }}
    />
  )} */
  }
  return (
    <div
      className="d-center flex-wrap pl-0 pr-1 assignTo rounded-pill shadow-sm"
      style={{
        border: "1px solid rgb(33 150 243 / 34%)",
        minHeight: 32,
        overflow: "hidden",
      }}
    >
      {lead.assignedTo && (
        <Avatar
          className="border"
          id={lead.assignedTo}
          width={30}
          height={30}
        />
      )}
      <StyledControl
        label=""
        labelClassname="m-0"
        type="selectGroups"
        value={lead.assignedTo}
        datas={filteredManagersBySelectedRole}
        groups={managersGroupsByRole()}
        dataIndex="id"
        dataLabel="name"
        change={handleChangeManager}
        noBg
        noPaddingOnFormGroup
        noMarginInsideLabel
        margin="m-0"
        placeholder="Assigner à..."
      />
    </div>
  );
};

const StyledControl = styled(Control)`
  select {
    border: 0 !important;
    border-radius: 5px;
    height: auto;
    padding-bottom: 0px !important;
    padding-top: 0px !important;
  }
  display: flex;
  align-items: center;
`;

export default AssignTo;
