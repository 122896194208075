import { useContext } from "react";
import styled from "styled-components";
import {
  CommentCreatedBy,
  CommentUpdateddBy,
} from "../common/CommentCreatedBy/CommentCreatedBy";
import CommentNameEdit from "../common/CommentNameEdit/CommentNameEdit";
//import CommentNameEdit from "../common/CommentNameEdit/CommentNameEdit";
import { CommentContext } from "../context/CommentContext";
import CommentActions from "./CommentActions/CommentActions";
//import CommentActions from "./CommentActions/CommentActions";

const CommentItem = ({ users }) => {
  const { comment, actions } = useContext(CommentContext);

  return (
    <StyledCommentItem className="row shadow-sm border rounded mx-0">
      <div className="col-12 px-1">
        <CommentCreatedBy comment={comment} />
      </div>
      <div className="col-12">
        <CommentNameEdit
          users={users}
          comment={comment}
          updateComment={actions.updateComment}
        />
      </div>
      <div className="col-12 px-1 d-flex justify-content-end flex-wrap">
        <CommentUpdateddBy comment={comment} />
      </div>
      <StyledActionPanel>
        <CommentActions />
      </StyledActionPanel>
    </StyledCommentItem>
  );
};

const StyledCommentItem = styled.div`
  background: white;

  padding: 5px;
`;

const StyledActionPanel = styled.div`
  position: absolute;
  right: -37px;
  background: white;
  width: 37px;
  overflow: hidden;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  box-shadow: inset 3px 0px 6px -6px black;
  & button {
    padding: 0px;
    width: 100%;
    height: 37px;
  }
`;

export default CommentItem;
