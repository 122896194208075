import React from "react";
import tools from "../../../../../../helpers/tools";
import UpdatedAt from "../UpdatedAt/UpdatedAt";
import useLeadItem from "../useLeadItem";
import "./createdAt.scss";

const CreatedAt = () => {
  const { lead } = useLeadItem();

  return (
    <div
      className="createdAt d-center d-flex flex-wrap text-start"
      style={{
        gap: 10,
        fontSize: 12,
      }}
    >
      {/* <i className="fa fa-calendar-alt mr-2" /> */}
      Créé {tools.formatDateHours(lead.createdAt)}
      <UpdatedAt />
    </div>
  );
};

export default CreatedAt;
