import { sidenavActions } from "../../../context/actions/sidenavActions";
import useStore from "../../../context/useStore";
import ProcedureTimeline from "../../pages/ClientListPage/ClientList/ClientItem/CompanyList/CompanyItem/ProcedureTimeline";

const CompanyStatuses = ({ company }) => {
  const [state, dispatch] = useStore();
  const snActions = sidenavActions(state, dispatch);

  const onOpenProcedures = (client) => {
    snActions.updateSidenav({
      isOpen: true,
      content: (
        <>
          <div className="text-lg mb-2">
            Procédures de <strong>{client.company}</strong>
          </div>
          <ProcedureTimeline procedures={client.proceduresCollectives} />
        </>
      ),
    });
  };

  return (
    <>
      {company.isBlackListed === true && (
        <span
          className="badge badge-dark cursor-pointer"
          onClick={() => alert("Cette entreprise est blacklistée")}
        >
          Blacklistée
        </span>
      )}
      {company.isDelisted && (
        <span
          className="badge badge-danger cursor-pointer"
          onClick={() => alert("Cette entreprise est radiée")}
        >
          Radiée
        </span>
      )}
      {company.proceduresCollectives?.length > 0 && (
        <span
          className="badge badge-orange text-white cursor-pointer"
          onClick={() => onOpenProcedures(company)}
        >
          {company.proceduresCollectives?.length} Procédures
        </span>
      )}
    </>
  );
};

export default CompanyStatuses;
