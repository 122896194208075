import React from "react";
import styled from "styled-components";
import tools from "../../../../../helpers/tools";

const getInitials = (name) => {
  name = name || "N D";
  const words = name.trim().split(/\s+/);
  const initials = words
    .slice(0, 2) // Take only the first two words
    .map((word) => {
      // Handle multi-character emojis and non-alphabetic characters
      const firstChar = [...word][0];
      return firstChar;
    })
    .join("");
  return initials;
};

const hashStringToColor = (str) => {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  const color = `hsl(${hash % 360}, 30%, 50%)`;
  return color;
};

export const CommentCreatedBy = ({ comment }) => {
  return (
    <StyledCommentCreatedBy>
      <div className="d-flex align-items-center flex-wrap">
        <InitialsCircle
          color={hashStringToColor(getInitials(comment.createdBy))}
        >
          {getInitials(comment.createdBy)}
        </InitialsCircle>
        <span>{comment.createdBy}</span>
      </div>
      <span>{tools.formatDateComment(comment.createdAt)}</span>
    </StyledCommentCreatedBy>
  );
};
export const CommentUpdateddBy = ({ comment }) => {
  return (
    <>
      {comment.updatedBy && (
        <>
          <div className="comment-tooltip-button">
            <i className="fa fa-info-circle text-grey" /> <i>modifié</i>
            <StyledCommentUpdatedBy className="comment-tooltip shadow-sm rounded">
              {comment.updatedBy} {tools.formatDateComment(comment.updatedAt)}
            </StyledCommentUpdatedBy>
          </div>
        </>
      )}
    </>
  );
};

const StyledCommentCreatedBy = styled.div`
  color: gray;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const StyledCommentUpdatedBy = styled.div`
  color: gray;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const InitialsCircle = styled.div`
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: ${(props) => props.color};
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-weight: bold;
  margin-right: 8px;
  white-space: nowrap;
`;
