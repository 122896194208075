import React, { useState, useEffect } from "react";
import Control from "../../common/Control";
import DynamicList from "../../common/DynamicList";
import Loader from "../../common/Loader";
import { DateTime } from "luxon";
import tools from "../../../helpers/tools";

const ClientListFilters = ({
  search,
  updateClientSearch,
  errors,
  nbResults,
  startSearch,
  isLoading,
}) => {
  const [searchText, setSearchText] = useState("Rechercher");

  useEffect(() => {
    setSearchText("Rechercher");
  }, [search]);

  useEffect(() => {
    if (nbResults > 0) setSearchText(nbResults + " résultats trouvés");
  }, [nbResults]);

  return (
    <>
      <div className="col-12  justify-content-center">
        <input
          autocomplete="false"
          name="hidden"
          type="text"
          style={{ display: "none" }}
        ></input>
        <Control
          margin={"my-2"}
          label="Recherche (email, nom, prénom, numéro de mobile, société, siret, ape, titre contrat ou numéro contrat)"
          name="searchString"
          value={search.client.searchString}
          change={updateClientSearch}
          error={errors}
          autocomplete="off"
        />
      </div>
      <div className="col-12 col-md-6">
        <Control
          label="Entre le"
          value={tools.getDateFrom(search.client.beginAt)}
          change={(e) =>
            updateClientSearch({
              target: {
                name: "beginAt",
                value: tools.getDateFrom(e.target.value),
              },
            })
          }
          type="date"
          error={errors}
          name="beginAt"
        />
      </div>
      <div className="col-12 col-md-6">
        <Control
          label="et le"
          value={tools.getDateFrom(search.client.endAt)}
          change={(e) =>
            updateClientSearch({
              target: {
                name: "endAt",
                value: tools.getDateFrom(e.target.value),
              },
            })
          }
          type="date"
          error={errors}
          name="endAt"
        />
      </div>

      <div className="col-12 justify-content-center d-flex font-weight-bold">
        <button
          style={{ fontWeight: "600" }}
          className={
            "btn  d-flex align-items-center w-100 justify-content-center mb-2  " +
            (searchText == "Rechercher" ? "btn-primary shadow" : "btn-success")
          }
          onClick={startSearch}
        >
          {isLoading ? <Loader color="white" /> : searchText}
        </button>
      </div>
    </>
  );
};

export default ClientListFilters;
