import tools from "../../../../../helpers/tools";

function ItemCreatedAt(props) {
  return (
    <div
      className="createdAt d-center d-flex  text-start flex-wrap createdAt"
      style={{
        gap: 10,
      }}
    >
      <div>
        Créé {tools.formatDateHours(props.simplifiedFormItem.createdAt)}
      </div>
      {/* <i className="fa fa-calendar-alt mr-2" /> */}
      <div>
        Modifié {tools.formatDateHours(props.simplifiedFormItem.updatedAt)}
      </div>
    </div>
  );
}

export default ItemCreatedAt;
