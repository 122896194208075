import { DateTime } from "luxon";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";

const procedureSample = [
  {
    annonceId: "A201901812706",
    tribunal: "TRIBUNAL MIXTE DE COMMERCE DE SAINT-PIERRE",
    jugement: {},
    urlAnnonce:
      "https://www.bodacc.fr/pages/annonces-commerciales-detail/?q.id=id:A201901812706",
    typeAvis: "annulation",
    numeroAnnoncePrecedente: "3271",
  },
  {
    annonceId: "A201901071748",
    tribunal: "TRIBUNAL DE GRANDE INSTANCE DE ST-PIERRE",
    jugement: {
      famille: "Extrait de jugement",
      nature: "Jugement de plan de redressement",
      date: "2019-05-14T00:00:00.000Z",
      complementJugement:
        "jugement arrêtant le plan de redressement pour une durée de 10 ans. Commissaire à l’execution du plan : la SELARL HIROU prise en la personne de Me Laurent HIROU, 8, rue Labourdonnais, 97400 ST Denis. N° RG : 2018/003668",
      type: "initial",
    },
    urlAnnonce:
      "https://www.bodacc.fr/pages/annonces-commerciales-detail/?q.id=id:A201901071748",
    typeAvis: "annonce",
    numeroAnnoncePrecedente: null,
  },
  {
    annonceId: "A201901743271",
    tribunal: "TRIBUNAL MIXTE DE COMMERCE DE SAINT-PIERRE",
    jugement: {
      famille: "Extrait de jugement",
      nature: "Jugement de plan de redressement",
      date: "2019-05-14T00:00:00.000Z",
      complementJugement:
        "Jugement arrêtant le plan de redressement, durée du plan 10 ans, nomme Commissaire à l'exécution du plan Selarl Hirou prise en la personne de Me Laurent Hirou - 8 rue Labourdonnais 97400 Saint-Denis.",
      type: "initial",
    },
    urlAnnonce:
      "https://www.bodacc.fr/pages/annonces-commerciales-detail/?q.id=id:A201901743271",
    typeAvis: "annonce",
    numeroAnnoncePrecedente: null,
  },
  {
    annonceId: "A201801681264",
    tribunal: "TRIBUNAL DE GRANDE INSTANCE DE ST-PIERRE",
    jugement: {
      famille: "Jugement d'ouverture",
      nature:
        "Jugement d'ouverture d'une procÃ©dure de redressement judiciaire",
      date: "2018-07-24T00:00:00.000Z",
      complementJugement:
        "jugement dâouverture de redressement judiciaire. Date de cessation des paiements : 24 janvier 2017. Les dÃ©clarations de crÃ©ances sont Ã  dÃ©poser dans les 2 mois suivant la prÃ©sente publication auprÃ¨s du mandataire judiciaire : SELARL HIROU, prise en la personne de Me Laurent HIROU, 8, rue Labourdonnais, 97400 Saint-Denis. NÂ° RG : 2018/002426",
      type: "initial",
    },
    urlAnnonce:
      "https://www.bodacc.fr/pages/annonces-commerciales-detail/?q.id=id:A201801681264",
    typeAvis: "annonce",
    numeroAnnoncePrecedente: null,
  },
  {
    annonceId: "A201900832396",
    tribunal: "TRIBUNAL MIXTE DE COMMERCE DE ST-PIERRE",
    jugement: {
      famille: "Avis de dépôt",
      nature: "Dépôt de l'état des créances",
      date: "2019-03-12T00:00:00.000Z",
      complementJugement:
        "avis de dépôt de l’état des créances L. 641-13 au tribunal mixte de commerce de St-Pierre où les réclamations seront recevables dans un délai de un mois à compter de la date de la présente publication. N° RG : 2018/002426",
      type: "initial",
    },
    urlAnnonce:
      "https://www.bodacc.fr/pages/annonces-commerciales-detail/?q.id=id:A201900832396",
    typeAvis: "annonce",
    numeroAnnoncePrecedente: null,
  },
];

const ProcedureTimeline = ({ procedures }) => {
  console.log(procedures);

  /*
  when jugement.date is null, it means that this entry
  should not appear in the timeline, but add some information into another entry.
  We will filter the procedure to get the numeroAnnoncePrecedente from these entries,
  and find the other entry which has and annonceId that finishes with this numeroAnnoncePrecedente.
  Finally we will add the typeAvis of the first entry to a new autresAvis array attribute of the second entry.
  

  */
  const proceduresWithAutresAvis = () => {
    const updatedProcedures = [];
    const autresAvisMap = new Map();

    procedures.forEach((procedure) => {
      if (!procedure.jugement.date) {
        const numeroAnnoncePrecedente = procedure.numeroAnnoncePrecedente;
        const autreAvis = procedures.find((p) =>
          p.annonceId.endsWith(numeroAnnoncePrecedente)
        );

        if (autreAvis) {
          if (!autresAvisMap.has(autreAvis.annonceId)) {
            autresAvisMap.set(autreAvis.annonceId, {
              ...autreAvis,
              autresAvis: [],
            });
          }
          autresAvisMap
            .get(autreAvis.annonceId)
            .autresAvis.push(procedure.typeAvis);
        }
      } else {
        updatedProcedures.push({ ...procedure });
      }
    });

    autresAvisMap.forEach((value) => {
      updatedProcedures.push(value);
    });

    return updatedProcedures;
  };

  const updatedProcedures = proceduresWithAutresAvis();

  console.log("proceduresWithAutresAvis", proceduresWithAutresAvis);
  return (
    <VerticalTimeline layout={"1-column"}>
      {updatedProcedures
        .sort((a, b) => {
          //sort by date descending
          return (
            DateTime.fromISO(b.jugement.date) -
            DateTime.fromISO(a.jugement.date)
          );
        })
        .map((event, evk) => (
          <VerticalTimelineElement
            className="vertical-timeline-element--work"
            contentStyle={{
              color: "#404040",
              padding: "8px 13px",
              boxShadow: "none",
              borderRadius: 0,
              // boxShadow: "0 -3px 0 " + eventColor + " ,0 0 15px -3px #afafaf",
            }}
            contentArrowStyle={{}}
            date={DateTime.fromISO(event.jugement.date).toFormat("dd/MM/yyyy")}
            iconStyle={{
              background: "white",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: 30,
              height: 30,
              boxShadow:
                "0 0 0 4px #3e75ff, inset 0 2px 0 rgba(0, 0, 0, 0.08), 0 3px 0 4px rgba(0, 0, 0, 0.05), 0 0 15px -3px #afafaf",
            }}
            icon={<i className={"fa text-primary fa-calendar"} />}
          >
            <div
              className="d-flex flex-column align-items-start"
              style={{ gap: 5 }}
            >
              <span className="font-weight-bold">
                {DateTime.fromISO(event.jugement.date).toFormat("dd/MM/yyyy")}
              </span>
              <span className="badge badge-default border">
                {event.jugement.famille}
              </span>

              <details>
                <summary>{event.jugement.nature}</summary>
                <div className="py-2">
                  <div className="pb-2">
                    {event.jugement.complementJugement}
                  </div>
                  <div style={{ fontSize: 10, lineHeight: "12px" }}>
                    Tribunal : {event.tribunal}
                  </div>
                </div>
              </details>
              {event.autresAvis?.length > 0 && (
                <div className="">
                  <span style={{ fontSize: 10 }}>Autres avis </span>

                  {event.autresAvis.map((autreAvis, aak) => (
                    <span className="badge badge-dark" key={`autreAvis${aak}`}>
                      {autreAvis}
                    </span>
                  ))}
                </div>
              )}
              <a
                href={event.urlAnnonce}
                target="_blank"
                className="text-primary"
              >
                Voir l'annonce <i className="fa fa-external-link" />
              </a>
            </div>
          </VerticalTimelineElement>
        ))}
    </VerticalTimeline>
  );
};

export default ProcedureTimeline;
