import React from "react";
import styled from "styled-components";
import { sidenavActions } from "../../../../../../context/actions/sidenavActions";
import useStore from "../../../../../../context/useStore";
import useLeadItem from "../useLeadItem";
import LeadCancelSelector from "./LeadCancelSelector";
import StatusIcon from "./StatusIcon";

const StatusSelector = ({ onUpdateStatus = () => {} }) => {
  const [state, dispatch] = useStore();
  const sideActions = sidenavActions(state, dispatch);
  const { LEAD_STATUS } = state.constants.items;
  const { lead, actions } = useLeadItem();

  const handleStatusChange = (status) => {
    if (status == "CANCEL") {
      sideActions.updateSidenav({
        isOpen: true,
        content: (
          <LeadCancelSelector
            onValidate={(reason) => changeStatus(status, reason)}
          />
        ),
      });
    } else {
      actions.onUpdateStatus(status);
      onUpdateStatus(status);
    }
  };

  const changeStatus = (status, reason = null) => {
    sideActions.updateSidenav({ isOpen: false, content: null });
    actions.onUpdateStatus(status, reason);
    onUpdateStatus(status, reason);
  };

  return (
    <StyledStatusSelector className="shadow animated fadeIn faster rounded">
      {LEAD_STATUS.filter((s) => s.id != lead.status).map((status) => (
        <StatusIcon
          status={status.id}
          onClick={() => handleStatusChange(status.id)}
          showName
        />
      ))}
    </StyledStatusSelector>
  );
};

const StyledStatusSelector = styled.div`
  position: absolute;
  top: 40px;
  left: -4px;
  padding: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: white;
  gap: 3px;
  z-index: 9999;
`;

export default StatusSelector;
