import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import styled from "styled-components";
import tools from "../../../../helpers/tools";
import Loader from "../../../common/Loader";
import useBlackListItem from "./useBlackListItem";
import CompanyStatuses from "../../../common/CompanyInfo/CompanyStatuses";

const BlackListItem = ({ blackListData }) => {
  const { actions, blackListItem } = useBlackListItem();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    actions.initItem(blackListData);
  }, [blackListData]);

  const removeCompany = () => {
    setIsLoading(true);
    if (
      window.confirm("Souhaitez-vous retirer cette société de la liste noire ?")
    ) {
      actions
        .removeFromBlacklist(blackListItem.id)
        .then((res) => {
          toast.success("Cette société a bien été retirée de la liste noire.");
          setIsLoading(false);
          window.location.reload(false);
        })
        .catch((err) => {
          console.log(err);
          setIsLoading(false);
        });
    }
  };

  return !blackListItem ? null : (
    <StyledBlackListItem
      className="col-6"
      id={`blackListItem${blackListItem.id}`}
    >
      <div className="info__block mb-1 rounded bg-white p-3">
        {blackListItem.client ? (
          <>
            <span className="badge badge-primary"> Client Perceval </span>
            <span className="pl-2">
              <CompanyStatuses company={blackListItem.client} />
            </span>
          </>
        ) : (
          <span className="badge badge-light">Open Data</span>
        )}
        <div className="info__company  ">{blackListItem.name}</div>
        <div className="info__siret">
          {tools.formatFollowingPattern(blackListItem.siret, [3, 3, 3, 5], " ")}
        </div>
        <div className="info__item">
          {blackListItem.location?.postalCode} {blackListItem.location?.city}
          <br />
          {blackListItem.ape}
        </div>

        <StyledBlackListItemButton>
          <div className=" mt-2">
            <button
              className="btn text-white bg-dark btn-sm"
              onClick={removeCompany}
            >
              {isLoading == true ? (
                <Loader />
              ) : (
                <>
                  {" "}
                  <i class="fa fa-unlock-alt" /> Retirer de la liste noire
                </>
              )}
            </button>
          </div>
        </StyledBlackListItemButton>
      </div>
    </StyledBlackListItem>
  );
};

const StyledBlackListItem = styled.div`
  margin-top: 10px;
  & .info__block {
    margin-left: 5px;
    width: 100%;
  }
  & .info__company {
    overflow: hidden;
    width: 100%;
    max-width: 100%;
    font-size: 18px;
    font-weight: 600;
  }
  & .info__siret {
    font-size: 12px;
    color: var(--color-default-dark);
  }
`;

const StyledBlackListItemButton = styled.div`
  margin-top: 10px;
`;

export default BlackListItem;
