export const filterContainerClassName =
  "row mx-0 filter-container p-2 py-3 bg-white rounded shadow-sm mb-3 ";
export const searchButtonContainerClassName =
  "col-12 d-flex justify-content-start";
export const searchButtonType = "outline-primary";
export const searchButtonClassName = "d-center ";
export const searchButtonLoaderColor = "#3e75ff";
export const commonControlProps = {
  noMarginOnFormGroup: true,
  noPaddingOnFormGroup: true,
  margin: "m-0",
  noBg: true,
};
