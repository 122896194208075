import React from "react";
import styled from "styled-components";

const CommentNameView = ({ comment }) => {
  return (
    <OverflowContainer>
      {/* <i
        className="fa fa-comment-dots mr-1 float-left"
        style={{ paddingTop: 4 }}
      /> */}
      <div
        className="ck-content"
        style={{ minHeight: 0 }}
        dangerouslySetInnerHTML={{ __html: comment.text }}
      />
    </OverflowContainer>
  );
};

const OverflowContainer = styled.div`
  position: relative;
  color: black;
  padding-top: 5px;
`;

export default CommentNameView;
