import React from "react";
import styled from "styled-components";
import useSimplifiedFormItem from "./useSimplifiedFormItem";

const ItemBottomPanel = ({ offsetBottom = 300, children }) => {
  const { actions } = useSimplifiedFormItem();

  return (
    <StyledItemBottomPanel offsetBottom={offsetBottom}>
      <div
        className="fabArrow d-center cursor-pointer"
        onClick={actions.closeBottomPanel}
      >
        <i className="fa fa-chevron-down" />
      </div>
      {children}
    </StyledItemBottomPanel>
  );
};

const StyledItemBottomPanel = styled.div`
  padding: 50px 17px;

  position: relative;
  /* box-shadow: inset 0px 6px 8px -10px black; */
  max-height: calc(100vh - ${({ offsetBottom }) => offsetBottom}px);
  overflow-x: auto;

  .fabArrow {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: white;
    position: absolute;
    left: 50%;
    top: 5px;
    transform: translateX(-50%);
    & i {
      transition: 300ms;
    }

    &:hover {
      i {
        transform: rotate(-180deg);
      }
    }
  }
`;

export default ItemBottomPanel;
