import useClientList from "./useClientList";

const ClientCount = () => {
  const { isLoadingClients, detailedCount } = useClientList();

  return (
    <div className="text-left">
      {isLoadingClients ? (
        "Recherche en cours..."
      ) : (
        <div>
          <div>{detailedCount.users} clients</div>
          <div>
            Sur cette page : {detailedCount.clients} sociétés /{" "}
            {detailedCount.policies} contrats
          </div>
        </div>
      )}
    </div>
  );
};

export default ClientCount;
