import React, { useState, useEffect, useRef } from "react";
import Validation from "./Validation";
import CheckboxInput from "./Inputs/CheckboxInput";
import TextInput from "./Inputs/TextInput";
import NumberInput from "./Inputs/NumberInput";
import DateInput from "./Inputs/DateInput";
import SelectInput from "./Inputs/SelectInput";
import EmailInput from "./Inputs/EmailInput";
import TelInput from "./Inputs/TelInput";
import FloatInput from "./Inputs/FloatInput";
import PasswordInput from "./Inputs/PasswordInput";
import RangeInput from "./Inputs/RangeInput";
import CheckboxListInput from "./Inputs/CheckboxListInput";
import CheckBtnListInput from "./Inputs/CheckBtnListInput";
import BtnListInput from "./Inputs/BtnListInput";
import AutocompleteInput from "./Inputs/AutocompleteInput";
import TextareaInput from "./Inputs/TextareaInput";
import DateTimeInput from "./Inputs/DateTimeInput";
import SelectListInput from "./Inputs/SelectListInput";
import SelectGroupsInput from "./Inputs/SelectGroupsInput";

const Control = (props) => {
  const componentTypes = {
    checkbox: CheckboxInput,
    text: TextInput,
    textarea: TextareaInput,
    number: NumberInput,
    float: FloatInput,
    date: DateInput,
    datetime: DateTimeInput,
    email: EmailInput,
    select: SelectInput,
    tel: TelInput,
    password: PasswordInput,
    range: RangeInput,
    checkboxList: CheckboxListInput,
    btnList: BtnListInput,
    checkBtnList: CheckBtnListInput,
    autocomplete: AutocompleteInput,
    selectList: SelectListInput,
    selectGroups: SelectGroupsInput,
  };

  var TargetComponent = componentTypes[props.type];
  const [isComplete, setIsComplete] = useState(true);
  const [hasValue, setHasValue] = useState(false);

  useEffect(() => {
    validateInput();
    setHasValue(
      props.type != "checkbox"
        ? Validation.required(props.value)
        : props.checked
    );
  }, [props.value, props.isValid, props.checked]);

  useEffect(() => {
    if (isComplete && props.setError) {
      props.setError({ ...props.error, [props.name]: false });
    }
  }, [isComplete]);

  useEffect(() => {
    if (props.error && props.error[props.name]) {
      setIsComplete(false);
    }
  }, [props.error]);

  const validateInput = () => {
    var inputValid = false;
    if (typeof props.isValid == "undefined") {
      inputValid = Validation.validate(props.type, props.value, props.required);
    } else {
      inputValid = props.isValid;
    }
    setIsComplete(inputValid);
    try {
      props.setIsValid(inputValid);
    } catch (err) {}
  };

  let hasError = props.error && props.error[props.name];

  return !props.type ? (
    <small>la prop type a été oubliée</small>
  ) : (
    <div
      // initial={{ opacity: 0, y: 20 }}
      // animate={{ opacity: 1, y: 0 }}
      // transition={{ ease: "easeIn", delay: 0.3 }}
      style={props.containerStyle || {}}
      className={`${hasError ? "hasError" : ""} ${
        !props.noFormGroup ? "form-group" : ""
      }
      ${props.className || ""}
      ${
        !props.noMarginOnFormGroup ? (props.margin ? props.margin : "mb-4") : ""
      }
      ${!props.noPaddingOnFormGroup ? "p-3" : ""}
      ${
        !props.noBg
          ? `${hasValue ? "bg-blue has-value" : ""} rounded shadow-small`
          : "noBg"
      }
      `}
    >
      <TargetComponent {...props} isValid={isComplete} />
    </div>
  );
};

export default Control;
