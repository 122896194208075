import Control from "../../../../../../../../../../../common/Controls/Control";
import SaveBtn from "../../../../../../../../../../../common/SaveBtn";
import useModifyReceiptForm from "./useModifyReceiptForm";

const ModifyReceiptForm = ({ receiptData }) => {
  const {
    PAYMENT_STATUS,
    receiptForm,
    isSavingPayment,
    errors,
    handleChangeInput,
    onSaveReceiptPayment,
    showForm,
    toggleShowForm,
  } = useModifyReceiptForm(receiptData);

  const commonControlProps = {
    noMarginOnFormGroup: true,
    noPaddingOnFormGroup: true,
    margin: "m-0",
    noBg: true,
    fullWidth: true,
  };

  return (
    <>
      <button
        className="btn btn-light btn-sm"
        onClick={toggleShowForm}
        data-priv="bo_viewpaymenthistory"
      >
        {showForm ? "Annuler" : "Ajouter un paiement"}
      </button>
      {showForm && (
        <div className="p-2 bg-blue rounded border mt-1">
          <Control
            label="Nouveau statut"
            type="select"
            name="status"
            value={receiptForm.status}
            change={handleChangeInput}
            datas={PAYMENT_STATUS}
            dataIndex="id"
            dataLabel="name"
            btnInline
            error={errors}
            {...commonControlProps}
          />
          <Control
            label="Montant (€)"
            placeholder="ex : 25.99"
            type="text"
            name="amount"
            value={receiptForm.amount}
            change={handleChangeInput}
            error={errors}
            {...commonControlProps}
          />
          <Control
            label="Sélectionnez les quittances concernées"
            type="checkBtnList"
            name="receipts"
            value={receiptForm.receipts}
            change={handleChangeInput}
            datas={receiptData.payments?.receipts || []}
            dataIndex="receiptId"
            dataLabel="name"
            btnInline
            error={errors}
            {...commonControlProps}
          />
          {!receiptData.payments.receipts && <p>Aucune quittance trouvée</p>}
          <Control
            label="Commentaire"
            type="textarea"
            name="comment"
            value={receiptForm.comment}
            change={handleChangeInput}
            error={errors}
            {...commonControlProps}
          />
          <SaveBtn
            className="w-100 btn-sm d-center"
            save={onSaveReceiptPayment}
            isSaving={isSavingPayment}
            text="Enregistrer"
          />
        </div>
      )}
    </>
  );
};

export default ModifyReceiptForm;
