import useStore from "../../../../../../context/useStore";

const ProductIcon = ({ product }) => {
  const [state] = useStore();
  const { QUOTATION_PRODUCT_TYPES } = state.constants.items;
  const productRef = QUOTATION_PRODUCT_TYPES.find((l) => l.id == product);

  if (!productRef) {
    return null;
  }
  return (
    <i
      className={`fa fa-${productRef.icon}`}
      style={{
        fontSize: 11,
        opacity: 0.5,
      }}
    />
  );
};

export default ProductIcon;
