import React from "react";
import LabelContainer from "../LabelContainer";

const SelectGroupsInput = ({
  k = -1,
  autoFocus,
  autocomplete = "on",
  inputStyle = {},
  inputClassName = "",
  type,
  disabled = false,
  id = null,
  value,
  change = () => {},
  placeholder = "Choisissez...",
  datas = [],
  dataIndex = "id",
  dataLabel = "name",
  dataLabelIndex = false,
  dataLabel2 = false,
  dataLabel3 = false,
  groups = [], // Add groups prop
  ...props
}) => {
  const valueChange = (e) => {
    var filteredValue = e.target.value;
    let changeEvent = {
      target: {
        name: props.name,
        value: filteredValue,
        k: k,
      },
    };

    change(changeEvent);
  };

  return (
    <LabelContainer {...props} value={value}>
      <select
        style={inputStyle ? inputStyle : {}}
        className={"form-control " + inputClassName}
        disabled={disabled ? "disabled" : false}
        value={value ? value : ""}
        name={props.name}
        id={k != -1 ? props.name + "-" + k : id ? id : props.name}
        onChange={valueChange}
        placeholder={placeholder}
        data-key={props["data-key"]}
        data-value={props["data-value"]}
        onBlur={props.onBlur || null}
      >
        <option value="">{placeholder}</option>
        {groups.length > 0
          ? groups.map((group, groupIndex) => (
              <optgroup key={groupIndex} label={group.label}>
                {group.options.map((data, k) => (
                  <option
                    key={props.name + "-" + (data.id ? data.id : k)}
                    value={data[dataIndex]}
                    disabled={data.optionDisabled}
                  >
                    {dataLabelIndex
                      ? data[dataLabel][dataLabelIndex]
                      : data[dataLabel]}{" "}
                    {dataLabel2 ? " - " + data[dataLabel2] : ""}
                    {dataLabel3 ? " - " + data[dataLabel3] : ""}
                  </option>
                ))}
              </optgroup>
            ))
          : datas.map((data, k) => (
              <option
                key={props.name + "-" + (data.id ? data.id : k)}
                value={data[dataIndex]}
                disabled={data.optionDisabled}
              >
                {dataLabelIndex
                  ? data[dataLabel][dataLabelIndex]
                  : data[dataLabel]}{" "}
                {dataLabel2 ? " - " + data[dataLabel2] : ""}
                {dataLabel3 ? " - " + data[dataLabel3] : ""}
              </option>
            ))}
      </select>
    </LabelContainer>
  );
};

export default SelectGroupsInput;
