import React from "react";
import styled from "styled-components";
import useLeadItem from "../useLeadItem";
import ProductIcon from "./ProductIcon";

const LeadProduct = () => {
  const { lead } = useLeadItem();

  const getUnavailableMessage = () => {
    const mess = lead.productData?.PRODUCT_REASON_UNAVAILABLE;
    return mess ? `Produit non disponible ${mess}` : "";
  };

  return (
    <StyledLeadProduct
      className="d-center"
      style={{ textTransform: "uppercase" }}
    >
      <div
        className="d-flex flex-md-column align-items-center w-100"
        style={{ gap: 3 }}
      >
        <ProductIcon product={lead.productId} />
        <div>{lead.productId}</div>
      </div>
      <StyledProductStatus>
        {lead.isFinalized && (
          <StyledFinalizedIcon
            className="shadow cursor-pointer"
            color="success"
            title="Demande finalisée"
            onClick={() => alert("Demande finalisée")}
          >
            <i className="fa fa-check" />
          </StyledFinalizedIcon>
        )}
        {lead.productData?.PRODUCT_REASON_UNAVAILABLE && (
          <StyledFinalizedIcon
            color="danger"
            title={getUnavailableMessage()}
            onClick={() => alert(getUnavailableMessage())}
            clickable
          >
            <i className="fa fa-times" />
          </StyledFinalizedIcon>
        )}
      </StyledProductStatus>
    </StyledLeadProduct>
  );
};

export const StyledLeadProduct = styled.div`
  font-size: 16px;
  font-weight: 800;
  position: relative;
  /* color: var(--color-success); */
`;

export const StyledProductStatus = styled.div`
  position: absolute;
  top: 40px;
  z-index: 9;
  @media (max-width: 768px) {
    position: relative;
    left: 10px;
    top: 0px;
  }
`;

export const StyledFinalizedIcon = styled.div`
  border-radius: 50%;
  width: 15px;
  height: 15px;
  font-size: 7px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  cursor: ${({ clickable }) => (clickable ? "pointer" : "")};
  background: var(--${({ color }) => color});
  border: 1px solid white;
  border-radius: 100%;
`;

export default LeadProduct;
