import { useDropzone } from "react-dropzone";
import styled from "styled-components";
import Loader from "../../../../../../../../../common/Loader";

const DropZone = ({ onDropFiles, isUploading, dataPriv = "" }) => {
  const onDrop = (acceptedFiles) => {
    const allowedTypes = ["image/jpeg", "image/png", "application/pdf"];

    if (
      acceptedFiles.length > 0 &&
      allowedTypes.indexOf(acceptedFiles[0].type) != -1 &&
      !isUploading
    ) {
      onDropFiles(acceptedFiles);
    } else {
      alert(
        "Le fichier choisi est incorrect. Les formats acceptés sont jpeg, png et pdf."
      );
    }
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <div {...getRootProps()} data-priv={dataPriv}>
      <input {...getInputProps()} />
      <StyledDropzone className="d-center text-center p-2 rounded mb-2 ">
        {isUploading ? (
          <Loader />
        ) : isDragActive ? (
          <div>Déposer le fichier ici</div>
        ) : (
          <div>
            Déposez un fichier ici, ou cliquez pour parcourir vos dossiers
          </div>
        )}
      </StyledDropzone>
    </div>
  );
};

const StyledDropzone = styled.div`
  height: 76px;
  background: #f0f3f5;
  border: 1px dashed #b2b2b2;
  cursor: pointer;
  &:hover {
    background: #e9ecef;
  }
`;

export default DropZone;
