import React from "react";

const Field = ({ fieldData }) => {
  return (
    <div className="d-flex mb-1 ">
      <div className="w-50 font-weight-bold border-bottom">
        {fieldData.label}
      </div>
      <div className="w-50">{fieldData.value}</div>
    </div>
  );
};

const InfoGrid = ({ rows }) => {
  return rows.map((row, rk) => (
    <div className="row">
      {row.map((col, ck) => (
        <div className={`col-12 col-md-${col.size}`} key={`r${rk}c${ck}`}>
          {col.title ? (
            <div className="my-3 font-weight-bold text-primary">
              {col.title}
            </div>
          ) : null}
          {col.fields.map((field, fk) => (
            <Field fieldData={field} key={`r${rk}c${ck}f${fk}`} />
          ))}
        </div>
      ))}
    </div>
  ));
};

export default InfoGrid;
