import React, { useEffect, useRef, useState } from "react";
import StatusIcon from "./StatusIcon";
import StatusName from "./StatusName";
import StatusSelector from "./StatusSelector";
import ViewCancelReason from "./ViewCancelReason";
import useOnClickOutside from "../../../../hooks/useClickOutside/useClickOutside";

const LeadStatus = ({ lead, onUpdateStatus }) => {
  const ref = useRef();
  const [statusSelectorOpen, setStatusSelectorOpen] = useState(false);

  const toggleStatusSelector = (status, reason) => {
    console.log("toggleStatusSelector", status, reason, lead.statut);
    if (status && status !== lead.statut) {
      onUpdateStatus(status, reason);
    }
    setStatusSelectorOpen(!statusSelectorOpen);
  };
  useOnClickOutside(ref, () => setStatusSelectorOpen(false));

  if (!lead.statut) return null;
  return (
    <div
      className="lead-status-selector d-center flex-row-reverse flex-md-column justify-content-md-start flex-md-column position-relative"
      ref={ref}
    >
      <StatusIcon status={lead.statut} onClick={() => toggleStatusSelector()} />
      <div>
        <StatusName status={lead.statut} />
        {lead.statut == "CANCEL" && (
          <ViewCancelReason
            cancelReason={lead.cancelReason}
            cancelText={lead.cancelText}
          />
        )}
      </div>
      {statusSelectorOpen && (
        <StatusSelector lead={lead} onUpdateStatus={toggleStatusSelector} />
      )}
    </div>
  );
};

export default LeadStatus;
