import { Provider } from "jotai";
import { DateTime } from "luxon";
import React, { useContext, useEffect } from "react";
import styled from "styled-components";
import colors from "../../../../helpers/colors";
import tools from "../../../../helpers/tools";
import CommentList from "../../../common/CommentList/CommentList";
import CommentListView from "../../../common/CommentList/CommentListView/CommentListView";
import EmailLink from "../../../common/CommentList/common/EmailLink/EmailLink";
import PhoneLink from "../../../common/CommentList/common/PhoneLink/PhoneLink";
import CompanyInfo from "../../../common/CompanyInfo/CompanyInfo";
import DocumentList from "../../../common/DocumentList/DocumentList";
import LabelValuePair from "../../../common/LabelValuePair/LabelValuePair";
import { PanelSectionTitle } from "../../../common/layout/StyledComponents/StyledComponents";
import LegalFormValue from "../../../common/LegalFormValue/LegalFormValue";
import ProductInfos from "../../../common/ProductInfos/ProductInfos";
import SaveBtn from "../../../common/SaveBtn";
import OpenDetailButton from "../../LeadListPage/LeadList/LeadItem/OpenDetailButton/OpenDetailButton";
import { PjItemContext } from "../pjItemContext";
import DateInfo from "./DateInfo";
import ExpireBadge from "./ExpireBadge";
import MailHistory from "./MailHistory/MailHistory";
import PjBottomPanel from "./PjBottomPanel/PjBottomPanel";
import TopBadge from "./TopBadge";
import usePj from "./usePj";
import { useLocation } from "react-router-dom";
import CompanyStatuses from "../../../common/CompanyInfo/CompanyStatuses";
const queryString = require("query-string");

const PjItem = () => {
  const { pjItem, managers, cancelReasons } = useContext(PjItemContext);
  const {
    pj,
    bottomPanelContent,
    isSearchingFiles,
    isFilesLoaded,
    resiliationOpen,
    actions,
    isCanceling,
    isResending,
  } = usePj();
  const location = useLocation();
  useEffect(() => {
    actions.initPj(pjItem);
  }, [pjItem]);

  const statusColor = pj?.isPastDue ? "danger" : "info";

  useEffect(() => {
    const parsed = queryString.parse(location.search, {
      arrayFormat: "bracket",
    });
    let tm = null;
    if (pj?.id && parsed.policyId == pj.id) {
      tm = setTimeout(() => {
        document
          .querySelector(`#pjitem${pj.id}`)
          .scrollIntoView({ behavior: "smooth" });
      }, 1000);
    }
    return clearTimeout(tm);
  }, [pj?.id]);

  return !pj ? null : (
    <StyledPjItem
      id={`pjitem${pj.id}`}
      className={`shadow-sm rounded mb-3 position-relative ${
        resiliationOpen ? "animated pulse infinite slow" : ""
      } ${pj.isCanceled ? "canceled" : ""}`}
      style={{
        borderLeft: `3px ${
          pj.isCanceled ? "dashed" : "solid"
        } var(--color-${statusColor})`,
      }}
    >
      <div className="row mx-0 bg-white py-3 rounded-top animated fadeIn">
        <TopBadge>
          <DateInfo>{tools.formatDate(pj.productData.beginAt)}</DateInfo>
          <i className="fa fa-arrow-right mx-2" />
          <DateInfo textColor={statusColor}>
            {tools.formatDate(pj.productData.realEndAt)}
          </DateInfo>
        </TopBadge>
        <TopBadge position="right" textColor={statusColor}>
          <ExpireBadge expireDate={pj.productData.realEndAt} />
          {!pj.isCanceled && (
            <SaveBtn
              type="link"
              className="text-dark p-0"
              style={{
                fontSize: 12,
                fontWeight: "400",
              }}
              save={() => actions.openResiliation({ cancelReasons })}
              text="Résilier"
              isSaving={isCanceling}
              loaderColor={colors["event-success"]}
            />
          )}
        </TopBadge>
        <div className="col-12 col-md-4">
          <CompanyInfo
            className="pl-3"
            noBadge
            company={pj.client.company}
            siret={pj.client.siret}
            companyStatus={<CompanyStatuses company={pj.client} />}
            infos={[
              `${pj.client.location1?.postalCode} ${pj.client.location1?.city}`,
              `${pj.client.ape} - ${pj.client.companyActivity}`,
              tools.getFullName(pj.userCustomer),
              <EmailLink email={pj.userCustomer.email} />,
              <PhoneLink phone={pj.userCustomer.phone} />,

              <LabelValuePair
                label="Montant dernière quittance"
                value={`${tools.truenumberWithSpaces(
                  pj.productData.ANNUAL_PRICE
                )} €`}
              />,
              <OpenDetailButton
                className="mt-2"
                onClick={actions.openInfos}
                title={<>Plus d'infos</>}
              />,
            ]}
          />
        </div>
        <div className="col-12 col-md-4">
          <Provider>
            <OpenDetailButton
              onClick={actions.openComments}
              title={
                <>
                  Commentaires{" "}
                  <StyledCountBadge disabled={pj.comments.length === 0}>
                    {pj.comments.length}
                  </StyledCountBadge>
                </>
              }
            />
            <CommentListView
              initComments={pj.comments}
              onUpdateComments={() => {}}
              onUpdateComment={actions.onUpdateComment}
              onAddComment={actions.onAddComment}
              users={managers}
            />
          </Provider>
        </div>
        <div className="col-12 col-md-4 mt-5 mt-md-0">
          <div>
            Email de renouvellement
            <br />
            {!pj.isCanceled && (
              <SaveBtn
                save={actions.resendRenewMail}
                style={{ fontSize: 12 }}
                className="p-0"
                type="link"
                isSaving={isResending}
                text={
                  <>
                    {" "}
                    Renvoyer l'email
                    <i className="fa fa-paper-plane ml-1" />
                  </>
                }
                loaderColor={colors["event-success"]}
              />
            )}
          </div>
          <MailHistory emailData={pj.emailData} />
        </div>
      </div>
      {bottomPanelContent && (
        <div className="row mx-0 bg-white border-left border-right border-bottom rounded-bottom">
          <div className="col-12 px-0">
            <PjBottomPanel>
              {(() => {
                switch (bottomPanelContent) {
                  case "COMMENTS":
                    return (
                      <Provider>
                        {/* <PanelSectionTitleAbs>
                          Commentaires
                        </PanelSectionTitleAbs> */}
                        <CommentList
                          initComments={pj.comments}
                          onUpdateComment={actions.onUpdateComment}
                          onAddComment={actions.onAddComment}
                          onDeleteComment={actions.onDeleteComment}
                          users={managers}
                        />
                      </Provider>
                    );

                  case "INFOS":
                    return (
                      <>
                        <DocumentList
                          title="Documents de la société"
                          files={pj.files.filter(
                            (f) => f[0].entity.ref == "Client"
                          )}
                          isLoading={isSearchingFiles}
                        />
                        <Provider>
                          <ProductInfos
                            productId="PJ"
                            productData={pj.productData}
                          />
                        </Provider>

                        <PanelSectionTitle>Société</PanelSectionTitle>
                        <div className="row">
                          <div className="col-12 col-md-6">
                            <CompanyInfo
                              noBadge
                              company={pj.client.company}
                              siret={pj.client.siret}
                              infos={[
                                tools.getFullName(pj.userCustomer),
                                <PhoneLink phone={pj.userCustomer.phone} />,
                                <EmailLink email={pj.userCustomer.email} />,
                                <LabelValuePair
                                  label="Forme juridique"
                                  value={
                                    <LegalFormValue
                                      legalForm={pj.client.legalForm}
                                    />
                                  }
                                />,
                                <LabelValuePair
                                  label="Adresse"
                                  value={tools.getFullAddress(
                                    pj.client.location1
                                  )}
                                />,
                                <LabelValuePair
                                  label="CA"
                                  value={`${tools.truenumberWithSpaces(
                                    pj.client.turnover
                                  )} €`}
                                />,
                                <LabelValuePair
                                  label="Immatriculée le"
                                  value={
                                    pj.client.registrationAt
                                      ? tools.formatDate(
                                          pj.client.registrationAt
                                        )
                                      : "inconnue"
                                  }
                                />,
                                <LabelValuePair
                                  label="Effectif"
                                  value={
                                    pj.client.workforce === 0
                                      ? "0"
                                      : tools.truenumberWithSpaces(
                                          pj.client.workforce
                                        )
                                  }
                                />,

                                ,
                              ]}
                            />
                          </div>
                          {/* <div className="col-12 col-md-6">
                            {!pj.establishment.isHeadOffice && (
                              <CompanyInfo
                                isHeadOffice={pj.establishment.isHeadOffice}
                                company={pj.establishment.company}
                                siret={pj.establishment.siret}
                                infos={[
                                  <LabelValuePair
                                    label="Adresse"
                                    value={tools.getFullAddress(pj.establishment.location)}
                                  />,
                                  <LabelValuePair
                                    label="CA"
                                    value={`${tools.truenumberWithSpaces(
                                      pj.establishment.turnover
                                    )} €`}
                                  />,
                                  <LabelValuePair
                                    label="Immatriculée le"
                                    value={tools.formatDate(pj.establishment.registrationAt)}
                                  />,
                                  <LabelValuePair
                                    label="Effectif"
                                    value={
                                      pj.establishment.workforce === 0
                                        ? "0"
                                        : tools.truenumberWithSpaces(pj.establishment.workforce)
                                    }
                                  />,
                                  ,
                                ]}
                              />
                            )}
                          </div> */}
                        </div>
                      </>
                    );
                }
              })()}
            </PjBottomPanel>
          </div>
        </div>
      )}
    </StyledPjItem>
  );
};

const StyledPjItem = styled.div`
  margin-top: 50px;

  &.canceled {
    opacity: 0.4;
    filter: grayscale(1);

    &::after {
      content: "RÉSILIÉ";
      position: absolute;
      top: 50%;
      left: -53px;
      font-weight: 800;
      transform: rotate(-90deg) translateX(35%);
    }
  }
`;

const PanelSectionTitleAbs = styled.h4`
  position: relative;
  z-index: 99;
  top: 3px;
  left: 13px;
  padding: 8px 0px;
  padding-top: 0px;
  margin: 0;
  font-weight: 700;
  color: var(--color-success);
  font-size: 16px;
`;

const StyledCountBadge = styled.span`
  background: var(--primary);
  color: white;
  padding: 0px 3px;
  font-weight: 600;
  font-style: normal;
  border-radius: 5px;
  min-width: 15px;
  display: inline-block;
  text-align: center;
  font-size: 11px;
`;

export default PjItem;
