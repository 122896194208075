import React from "react";
import styled from "styled-components";

const OpenDetailButton = ({
  onClick,
  title,
  className = "",
  dataPriv,
  icon = "",
  inColumn = false,
  titleClassName = "py-1",
  ...props
}) => {
  return (
    <StyleDetailButton
      data-priv={dataPriv}
      onClick={onClick}
      className={` my-md-0  cursor-pointer ${className}`}
      {...props}
    >
      <div
        className={` px-0  rounded d-flex align-items-center justify-content-between ${
          inColumn ? "flex-md-column flex-row py-2 bg-transparent" : "py-1 "
        }`}
        style={{
          gap: 6,
        }}
      >
        {inColumn && (
          <i
            className={`fa text-center ${icon}`}
            style={{
              fontSize: 24,
              width: 30,
            }}
          />
        )}
        <strong
          className={`${titleClassName} text-center d-flex flex-fill align-items-center`}
          style={{ gap: 5 }}
        >
          {title}
        </strong>
        {!inColumn && <i className={`fa ml-2 ${icon}`} />}
      </div>
    </StyleDetailButton>
  );
};

const StyleDetailButton = styled.div`
  color: var(--color-primary);
  &:hover {
    color: var(--color-success);
  }
`;

export default OpenDetailButton;
