import React from "react";
import Page from "../../common/layout/Page";
import PartnerInfos from "./PartnerInfos/PartnerInfos";
import PartnerShortcuts from "./Shortcuts/PartnerShortcuts";
import Stats from "./Stats/Stats";
import useDashboard from "./useDashboard";

const Dashboard = () => {
  const { pageTitle, canSeeStats, canSeeShortcuts, isPartner } = useDashboard();

  return (
    <Page title={pageTitle}>
      {isPartner && <PartnerInfos />}
      {canSeeStats && <Stats />}

      {
        isPartner ? <PartnerShortcuts /> : ""
        // <Shortcuts />
      }
    </Page>
  );
};

export default Dashboard;
