import React from "react";
import useSimplifiedFormItem from "../useSimplifiedFormItem";
import CompanyInfo from "../../../../common/CompanyInfo/CompanyInfo";
import OpenDetailButton from "../../../LeadListPage/LeadList/LeadItem/OpenDetailButton/OpenDetailButton";
import tools from "../../../../../helpers/tools";
import PhoneLink from "../../../../common/CommentList/common/PhoneLink/PhoneLink";
import { WEB_URL } from "../../../../../config";
import { toast } from "react-toastify";
import EmailLink from "../../../../common/CommentList/common/EmailLink/EmailLink";
import { DateTime } from "luxon";

const ItemInfos = () => {
  const { simplifiedFormItem, actions } = useSimplifiedFormItem();

  let itemInfos = { badgeText: "Société", showCompanyLink: false };
  const { nom_entreprise, code_ape, activite } =
    simplifiedFormItem.formData?.selectedCompany || {};

  const { siret, code_postal, ville } =
    simplifiedFormItem.formData?.selectedEstablishment || {};

  const { civilite, prenom, nom, telephone, email } =
    simplifiedFormItem.parameters.client_lie || {};

  const formattedCompany = `${nom_entreprise || "Aucune entreprise"}`;
  const formattedSiret = `${siret || ""}`;
  const formattedAddress = [
    `${code_postal || ""} ${ville || ""}`,
    `${code_ape || ""} - ${activite || ""}`,
  ];
  const formattedClient = prenom
    ? `Client : ${tools.getFullName({
        gender: civilite,
        firstname: prenom,
        lastname: nom,
      })}`
    : "";

  const formattedPhone = telephone ? <PhoneLink phone={telephone} /> : "";
  const formattedEmail = email ? <EmailLink email={email} /> : "";
  const copyButton = simplifiedFormItem.parameters.est_public ? (
    <OpenDetailButton
      className="pt-3"
      titleClassName="py-0"
      onClick={() => {
        tools.copyTextToClipboard(
          `${WEB_URL}formulaire-simplifie/${simplifiedFormItem.uuid}`
        );
        toast.info("Lien copié dans le presse-papier");
      }}
      title={<>Copier le lien du formulaire</>}
    />
  ) : null;
  const editButton = (
    <OpenDetailButton
      className="py-0"
      titleClassName="py-0"
      onClick={actions.openInfos}
      title={<>Éditer</>}
    />
  );

  switch (simplifiedFormItem.parameters.product) {
    case "PJ":
      itemInfos = {
        ...itemInfos,
        company: formattedCompany,
        siret: formattedSiret,
        infos: [
          ...formattedAddress,
          formattedClient,
          formattedPhone,
          formattedEmail,
          copyButton,
          editButton,
        ],
      };
      break;
    case "AC":
      itemInfos = {
        ...itemInfos,
        company: formattedCompany,
        siret: formattedSiret,
        infos: [
          ...formattedAddress,
          formattedClient,
          formattedPhone,
          formattedEmail,
          copyButton,
          editButton,
        ],
      };
      break;
    case "MRP":
      itemInfos = null;
      break;
    case "CYBER":
      itemInfos = {
        ...itemInfos,
        company: formattedCompany,
        siret: formattedSiret,
        infos: [
          ...formattedAddress,
          formattedClient,
          formattedPhone,
          formattedEmail,
          copyButton,
          editButton,
        ],
      };
      break;
    default:
      itemInfos = null;
  }

  return <CompanyInfo isOpened={true} {...itemInfos} />;
};

export default ItemInfos;
