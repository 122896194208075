import React, { useContext } from "react";
import { LeadContext } from "../../LeadContext";
import useLeadItem from "../useLeadItem";
import "./linkedLeads.scss";

const LinkedLeads = () => {
  const { getLinkedLeads } = useContext(LeadContext);
  const { lead } = useLeadItem();

  const handleLinkedLeadClick = () => {
    getLinkedLeads(lead.quotationId);
  };

  return lead.nbLinkedLeads > 0 ? (
    <div className=" linkedLeads d-center  border rounded">
      <button
        className="btn btn-link btn-sm border-0"
        style={{ fontSize: 12 }}
        onClick={handleLinkedLeadClick}
      >
        {lead.nbLinkedLeads} demande(s) liée(s)
        <i className="fa fa-share-alt ml-2" />
      </button>
    </div>
  ) : null;
};

export default LinkedLeads;
