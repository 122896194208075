import { atom, useAtom } from "jotai";
import { useContext } from "react";
import useStore from "../../../../../../../../../../context/useStore";
import dataService from "../../../../../../../../../../helpers/dataService";
import { getPolicyReceipts } from "../../../../../../../../../../services/policyService";
import { ClientContext } from "../../../../../../ClientContext/ClientContext";
import useContextualTimeline from "../../../../../useContextualTimeline/useContextualTimeline";
import useCompanyPolicies from "../useCompanyPolicies";

const companyPolicyAtom = atom(null);
const policyAtom = atom(null);
const bottomPanelContentAtom = atom("");
const isLoadingReceiptsAtom = atom(false);

const usePolicyItem = () => {
  const [state] = useStore();
  const { POLICIES_STATUS, PERIODICITIES, PAYMENT_STATUS } =
    state.constants.items;
  const { products, productTypes, insuranceCompanies } =
    useContext(ClientContext);
  const { isLoadingCompanyPolicies } = useCompanyPolicies();
  const [policy, setPolicy] = useAtom(policyAtom);
  const [companyPolicy, setCompanyPolicy] = useAtom(companyPolicyAtom);
  const [bottomPanelContent, setBottomPanelContent] = useAtom(
    bottomPanelContentAtom
  );
  const [isLoadingPolicyReceipts, setIsLoadingPolicyReceipts] = useAtom(
    isLoadingReceiptsAtom
  );
  const { openTimeline } = useContextualTimeline();
  const bottomPanels = [
    {
      name: "Documents",
      content: "DOCUMENTS",
      dataPriv: "bo_viewcontractdocs",
    },
    { name: "Avenants", content: "AMENDMENTS", dataPriv: "bo_viewuserdetail" },
    { name: "Quittances", content: "RECEIPTS", dataPriv: "bo_viewuserdetail" },
    {
      name: "Timeline",
      content: "EVENTS",
      onClick: () => openTimeline({ model: "policies", id: policy.id }),
    },
  ];

  const initPolicy = (policyData) => {
    setPolicy(policyData);
  };
  const initCompanyPolicy = (companyPolicyData) => {
    setCompanyPolicy(companyPolicyData);
  };

  const openBottomPanel = (content) => {
    if (content == bottomPanelContent) {
      setBottomPanelContent("");
    } else {
      setBottomPanelContent(content);
    }
  };

  const onGetPolicyReceipts = () => {
    setIsLoadingPolicyReceipts(true);
    getPolicyReceipts(policy.id)
      .then((data) => {
        let policyReceipts = data;
        dataService.getMany(
          data.map((receipt) => ({
            type: "GET",
            path: `receipts/${receipt.id}/payments`,
            name: receipt.id,
          })),
          (recData) => {
            Object.keys(recData).forEach((receiptId) => {
              let recRef = recData[receiptId];
              policyReceipts.find((r) => r.id == receiptId).payments = recRef;
            });

            setPolicy({ ...policy, receipts: policyReceipts });
          },
          (err) => {},
          () => setIsLoadingPolicyReceipts(false)
        );
      })
      .catch((err) => setIsLoadingPolicyReceipts(false));
  };

  const isGroupamaProduct = [
    "504901",
    "504901 BTP",
    "504901+",
    "504901+ BTP",
  ].includes(policy?.product?.name);

  return {
    POLICIES_STATUS,
    PERIODICITIES,
    PAYMENT_STATUS,
    products,
    productTypes,
    insuranceCompanies,
    isLoadingCompanyPolicies,
    policy,
    companyPolicy,
    bottomPanels,
    bottomPanelContent,
    isLoadingPolicyReceipts,
    isGroupamaProduct,
    policyActions: {
      initPolicy,
      initCompanyPolicy,
      openBottomPanel,
      onGetPolicyReceipts,
    },
  };
};

export default usePolicyItem;
