import React from "react";

const Pagination = ({ page, nbPages, changePage }) => {
  var pages = [];
  var pageMin = page - 2 < 1 ? 1 : page - 2;
  var pageMax = page + 2 > nbPages ? nbPages : page + 2;
  for (let i = pageMin; i <= pageMax; i++) {
    pages.push(
      <li
        key={"page" + i}
        className={"page-item " + (page == i ? "active" : "")}
      >
        <button className="page-link" onClick={() => changePage(i)}>
          {i}
        </button>
      </li>
    );
  }

  return nbPages == 1 ? (
    <></>
  ) : (
    <>
      <ul className="pagination justify-content-center mb-0">
        {page > 1 && (
          <li className="page-item">
            <button className="page-link" onClick={() => changePage(1)}>
              <span aria-hidden="true">&laquo;</span>
            </button>
          </li>
        )}

        {page > 1 && (
          <li className="page-item">
            <button className="page-link" onClick={() => changePage(page - 1)}>
              <span aria-hidden="true">&lsaquo;</span>
            </button>
          </li>
        )}
        {pages}
        {nbPages > 3 && page + 3 < nbPages && (
          <li className="page-item">
            <button className="page-link bg-transparent " disabled>
              ...
            </button>
          </li>
        )}
        {nbPages > 3 && pageMax < nbPages && (
          <li className="page-item">
            <button className="page-link" onClick={() => changePage(nbPages)}>
              {nbPages}
            </button>
          </li>
        )}
        {page < nbPages && (
          <li className="page-item">
            <button className="page-link" onClick={() => changePage(page + 1)}>
              <span aria-hidden="true">&rsaquo;</span>
            </button>
          </li>
        )}

        {page < nbPages && (
          <li className="page-item">
            <button className="page-link" onClick={() => changePage(nbPages)}>
              <span aria-hidden="true">&raquo;</span>
            </button>
          </li>
        )}
      </ul>
    </>
  );
};

export default Pagination;
