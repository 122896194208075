import { useState } from "react";
import { toast } from "react-toastify";
import useStore from "../../../../../../../../../../../context/useStore";
import { postRegenerateCP } from "../../../../../../../../../../../services/policyService";
import Control from "../../../../../../../../../../common/Controls/Control";
import SaveBtn from "../../../../../../../../../../common/SaveBtn";
import Timer from "../../../../../../../../../../common/Timer/Timer";
import usePolicyItem from "../usePolicyItem";
import useBPPolicyDocuments from "./useBPPolicyDocuments";

const RegenerateCP = () => {
  const { policyDocumentsActions } = useBPPolicyDocuments();
  const { policy } = usePolicyItem();
  const [state] = useStore();
  const { AMENDMENT_TYPES } = state.constants.items;
  const [isGenerating, setIsGenerating] = useState(false);
  const [timer, setTimer] = useState(0);
  const [generationData, setGenerationData] = useState({
    amendmentType: "",
    date: "",
  });

  const onGenerateCP = () => {
    setIsGenerating(true);
    postRegenerateCP(policy.id, generationData)
      .then((data) => {
        setTimer(40);
        toast.success("La régénération du document est en cours...");
      })
      .finally(() => setIsGenerating(false));
  };

  const onTimerFinish = () => {
    setTimer(0);
    policyDocumentsActions.onGetPolicyDocuments();
  };

  const commonControlProps = {
    noMarginOnFormGroup: true,
    noPaddingOnFormGroup: true,
    margin: "m-0",
    noBg: true,
    fullWidth: true,
  };

  const handleChangeInput = (e) => {
    const { name, value } = e.target;
    setGenerationData({ ...generationData, [name]: value });
  };

  return (
    <div className="row mx-0">
      <div className="col-12 col-md-4">
        <Control
          label="Date de la signature"
          type="datetime"
          name="date"
          value={generationData.date}
          change={handleChangeInput}
          {...commonControlProps}
        />
      </div>
      <div className="col-12 col-md-4">
        <Control
          label="Type d'avenant"
          type="select"
          name="amendmentType"
          value={generationData.amendmentType}
          change={handleChangeInput}
          datas={AMENDMENT_TYPES}
          dataIndex="id"
          dataLabel="name"
          {...commonControlProps}
        />
      </div>
      <div className="col-12 col-md-4">
        {timer > 0 ? (
          <div className="d-center pt-3">
            <Timer timeLimit={timer} timerFinish={onTimerFinish} />
          </div>
        ) : (
          <SaveBtn
            className="btn-sm mt-4"
            isSaving={isGenerating}
            save={onGenerateCP}
            text="Regénérer les CP"
            dataPriv="bo_viewparameters"
          />
        )}
      </div>
    </div>
  );
};

export default RegenerateCP;
