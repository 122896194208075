import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import tools from "../../../../../../../helpers/tools";
import CompanyInfo from "../../../../../../common/CompanyInfo/CompanyInfo";
import LabelValuePair from "../../../../../../common/LabelValuePair/LabelValuePair";
import LegalFormValue from "../../../../../../common/LegalFormValue/LegalFormValue";
import useContextualTimeline from "../../useContextualTimeline/useContextualTimeline";
import BottomPanel from "./BottomPanel/BottomPanel";
import { CloseCompanyBottomPanelButton } from "./BottomPanel/CompanyBottomPanel/CompanyBottomPanel";
import CompanyPoliciesRecap from "./CompanyPoliciesRecap/CompanyPoliciesRecap";
import useCompanyItem from "./useCompanyItem";
import CompanyStatuses from "../../../../../../common/CompanyInfo/CompanyStatuses";
const queryString = require("query-string");

const CompanyItem = ({ companyData }) => {
  const { company, isDeleted, isDeleting, actions } = useCompanyItem();
  const { openTimeline } = useContextualTimeline();
  const location = useLocation();

  useEffect(() => {
    actions.initCompany(companyData);
  }, []);

  useEffect(() => {
    if (company?.id) {
      let parsed = queryString.parse(location.search, {
        arrayFormat: "bracket",
      });
      if (parsed?.panel && company.siret.includes(parsed.sirenOuSiret)) {
        setTimeout(() => {
          actions.openBottomPanel(parsed.panel);
        }, 1000);
      }
    }
  }, [company?.id]);

  return !company ? null : (
    <>
      <CompanyContainer
        isDeleted={isDeleted}
        className=" position-relative shadow-sm border"
        id={`leaditem${company.id}`}
      >
        <div
          className="row mx-0  py-3 animated fadeIn"
          style={
            {
              // borderBottom: "1px solid #d3d3d3",
            }
          }
        >
          <div className="col-12">
            <CompanyInfo
              className=""
              noBadge
              showCompanyLink={false}
              company={
                <button
                  onClick={() => actions.openBottomPanel("COMPANY")}
                  className="w-100 border mb-2 d-center btn  btn-default font-weight-bold d-flex flex-column shadow-sm"
                >
                  <span>{company.company}</span>
                </button>
              }
              siret={company.siret}
              companyStatus={<CompanyStatuses company={company} />}
              infos={[
                `${tools.getFullAddress(company.location1)}`,
                `${company.ape || ""} - ${company.companyActivity || ""}`,

                <LabelValuePair
                  label="Forme juridique"
                  value={<LegalFormValue legalForm={company.legalForm} />}
                />,
                <LabelValuePair
                  label="CA"
                  value={`${tools.truenumberWithSpaces(company.turnover)} €`}
                />,
                <LabelValuePair
                  label="Immatriculée le"
                  value={tools.formatDate(company.registrationAt)}
                />,
                <LabelValuePair
                  label="Effectif"
                  value={
                    company.workforce === 0
                      ? "0"
                      : tools.truenumberWithSpaces(company.workforce)
                  }
                />,
                <button
                  className="btn btn-link text-danger p-0"
                  style={{ fontWeight: "400" }}
                  onClick={actions.onRemoveCompany}
                  dataPriv="bo_deleteclient"
                  icon="fa-times"
                >
                  <span className="text-danger">
                    {isDeleting
                      ? "suppression en cours"
                      : "Supprimer cette société"}
                  </span>
                </button>,
              ]}
            />

            <div className="mt-3">
              <CompanyPoliciesRecap />
            </div>
          </div>
          {/* <div className="col-12 col-md-8 ">
            <div className="row">
              <div className="col-12 col-md-4">
                <OpenDetailButton
                  onClick={() => actions.openBottomPanel("COMPANY")}
                  title={<>Informations</>}
                  icon="fa-info-circle"
                  inColumn
                />
              </div>
              <div className="col-12 col-md-4">
                <OpenDetailButton
                  dataPriv="bo_viewcompanydocs"
                  onClick={() => actions.openBottomPanel("DOCUMENTS")}
                  title={<>Documents</>}
                  icon="fa-file"
                  inColumn
                />
              </div>

              {company.policies?.length > 0 && (
                <div className="col-12 col-md-4">
                  <OpenDetailButton
                    onClick={() => actions.openBottomPanel("POLICIES")}
                    title={<>Contrats</>}
                    icon="fa-file-contract"
                    inColumn
                  />
                </div>
              )}
              <div className="col-12 col-md-4">
                <OpenDetailButton
                  dataPriv="bo_viewcontractdocs"
                  onClick={() => actions.openBottomPanel("BILLS")}
                  title={<>Factures</>}
                  icon="fa-file-invoice-dollar"
                  inColumn
                />
              </div>
              <div className="col-12 col-md-4">
                <OpenDetailButton
                  onClick={() => actions.openBottomPanel("USERS")}
                  title={<>Utilisateurs</>}
                  icon="fa-users"
                  inColumn
                />
              </div>
              <div className="col-12 col-md-4">
                <OpenDetailButton
                  onClick={() =>
                    openTimeline({
                      model: "clients",
                      id: company.id,
                      searchString: "",
                      type: "",
                    })
                  }
                  title={<>Timeline</>}
                  icon="fa-calendar-alt"
                  inColumn
                />
              </div>
            </div> 
          </div>*/}
          <CloseCompanyBottomPanelButton />
        </div>
      </CompanyContainer>
      <BottomPanel />
    </>
  );
};

const CompanyContainer = styled.div`
  filter: ${({ isDeleted }) => (isDeleted ? "grayscale(1)" : "none")};
  /* border-left: 3px solid #d3d3d3; */
  margin-top: 10px;
  background: #f7f7f7;
  padding: 5px;
  border-radius: 5px;
  display: flex;

  /* border-bottom: 10px solid #ebecf7; */

  &::after {
    display: ${({ isDeleted }) => (isDeleted ? "flex" : "none")};
    content: "supprimée";
    width: 100%;
    height: 100%;
    color: white;
    position: absolute;
    z-index: 99;
    background: rgba(0, 0, 0, 0.32);
    top: 0;
    left: 0;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    font-weight: bold;
  }
`;

export default CompanyItem;
