import React, { useEffect } from "react";
import styled from "styled-components";
import CompanyList from "./CompanyList/CompanyList";
import UserInfos from "./UserInfos/UserInfos";
import useClientItem from "./useClientItem";

const ClientItem = ({ clientData }) => {
  const { client, actions } = useClientItem();

  useEffect(() => {
    actions.initClient(clientData);
  }, [clientData]);

  return !client ? null : (
    <StyledClientItem
      className="mb-3 bg-white shadow-sm rounded pb-2"
      id={`leaditem${clientData.id}`}
    >
      <div
        className="p-3 bg-white  mb-2 rounded"
        style={
          {
            // borderLeft: "1px solid #3e75ff",
          }
        }
      >
        {/* <PanelSectionTitle>Compte</PanelSectionTitle> */}
        <UserInfos />
      </div>
      <CompanyList />
    </StyledClientItem>
  );
};

const StyledClientItem = styled.div`
  margin-top: 20px;
  /* border-radius: 5px; */
  /* overflow: hidden; */
  /* border-left: 3px solid #3e75ff; */
  /* border-bottom: 3px solid #3e75ff; */
`;

export default ClientItem;
